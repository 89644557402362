<template>
  <div>
    <uploader-form @chosen="handleFilesChosen" />
    <div class="mb-4 flex justify-between px-4 text-gray-600 text-sm">
      <span
        >{{ this.uploads.length }} uploads </span
      >
      <span>{{ overProgress }}% complete</span>
    </div>
    <uploader-file
      v-for="(upload, i) in uploads"
      :key="i"
      :upload="upload"
    />
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import UploaderForm from "./UploaderForm.vue";
import UploaderFile from "./UploaderFile.vue";

export default {
  components: {
    UploaderForm,
    UploaderFile,
  },
  data() {
    return {
      uploads: []
    };
  },
  computed: {
    overProgress() {
      if (this.uploads.length === 0) {
        return 0;
      }
      let uploads = this.uploads.filter(
        upload => !upload.cancelled && !upload.failed
      );
      if (this.uploads.length === 0) {
        return 0;
      }
      return parseInt(
        uploads.reduce((a, b) => a + b.progress, 0) / uploads.length
      );
    },
  },
  methods: {
    determineEndpointFor(fileType) {
      return fileType;
    },
    handleUploadProgress(e) {
      this.uploads = this.uploads.map( upload => {
        if (e.id === upload.id) {
          upload.progress = e.progress;
        }
        return upload;
      });
    },
    handleFilesChosen( files ) {
      this.uploads.push(
        ...Array.from(files).map( file => {
          return {
            id: uuidv4(),
            file,
            width:0,
            height:0
          };
        })
      );
      this.$emit("banners", this.uploads);
    },
  },
};
</script>
