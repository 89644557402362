<template>
    <b-card-code title="New Banner Upload Information">
      <b-overlay 
        :show="show" 
        rounded="lg" 
        no-fade 
      >
          <div class="mb-5" :aria-hidden="show ? 'true' : null">
              <b-form 
                  ref="form"
                  class="mt-1"
                  @submit.prevent="createBannerForm"
              >
                  <b-row>
                      <b-col md="8">
                          <b-row>
                              <!-- Campaign list -->
                              <b-col md="12">
                                  <b-form-group
                                      label="Campaign"
                                      label-for="campaign"
                                  >
                                      <v-select
                                          v-model="campaign"
                                          :reduce="(option) => option.id"
                                          :options="campaigns"
                                          @input="getAdGroupList"
                                      />
                                  </b-form-group>
                              </b-col>
                              <!-- adgroup list -->
                              <b-col md="12">
                                  <b-form-group
                                      label="Adgroup"
                                      label-for="Adgroup"
                                  >
                                      <v-select
                                          v-model="adgroup"
                                          :reduce="(option) => option.id"
                                          :options="adgroups"
                                          :disabled="adgroupdisabled"
                                      />
                                  </b-form-group>
                              </b-col>

                              <!-- Banner Type -->
                              <b-col md="12">
                                  <b-form-group
                                      label="Select Banner Type"
                                      label-for="bannertype"
                                  >
                                      <v-select
                                          v-model="bannertype"
                                          :reduce="(option) => option.id"
                                          :options="[{id:1, label:'Image'},{id:2, label:'HTML5'}]"
                                      />
                                  </b-form-group>
                              </b-col>

                                <b-col md="12" v-if="bannertype==2">
                                    <b-form-group
                                        label="Banner Name"
                                        label-for="banner_name"
                                    >
                                        <b-form-input 
                                            v-model="banner_name"
                                            placeholder="Banner name"
                                        />
                                    </b-form-group>
                                </b-col>

                              <!-- quill editor -->
                              <b-col md="11" class="mx-auto" v-if="bannertype==2">
                                <code-mirror-editor
                                    v-model="htmlCode"
                                    :jsonIndentation="jsonIndentation"
                                    class="mt-3 border-primary"
                                    @chosen="getCode"
                                ></code-mirror-editor>
                              </b-col>

                              <!-- Images uploader -->
                              <b-col md="12" v-if="bannertype==1">
                                <uploader @banners="handleBanners" />
                              </b-col>
                              
                              <!-- submit and reset -->
                            <b-col cols="12" class="my-5 text-center">
                                <b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    type="submit"
                                    variant="primary"
                                    class="mr-1"
                                >
                                    Submit
                                </b-button>
                                <b-button
                                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                    type="reset"
                                    variant="outline-secondary"
                                    @click="reset"
                                >
                                    Reset
                                </b-button>
                            </b-col>

                          </b-row>
                      </b-col>
                  </b-row>
              </b-form>
          </div>
      </b-overlay>
    </b-card-code>
  </template>
  
  <script>
    import "codemirror/lib/codemirror.css";
    import BCardCode from '@core/components/b-card-code'
    import {
        BCard,
        BCardBody,
        BOverlay,
        BForm,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BButton,
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import vSelect from 'vue-select'
    import router from '@/router'
    import useJwt from '@/auth/jwt/useJwt'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import Uploader from "@/uploader/components/Uploader.vue";
    import CodeMirrorEditor from "@/codemirror/codeMirror"; //codemirror
    
  export default {
    components: {
      BCardCode,
      BCard,
      BCardBody,
      vSelect,
      BOverlay,
      BForm,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BButton,
      Uploader,
      CodeMirrorEditor
    },
    directives: {
        Ripple
    },
    data() {
      return {
        campaign:'',
        campaigns:[],
        adgroupdisabled:true,
        adgroup:'',
        adgroups:[],
        bannertype:1,
        show: false,
        jsonIndentation: 2, 
        autoFormatJson: false,
        htmlCode:'',
        banner_name:'',
        banners:[]
      }
    },
    methods: {
        reset(){
            console.log('testing reset');
        },
        getCode(code){
            this.htmlCode = code;
        },
        createBannerForm(){
            this.show = true;
            const accessToken = useJwt.getToken('accessToken');

            if( this.adgroup && this.bannertype == 1 ){
                var formData = new FormData();
                this.banners.forEach( f => {
                    formData.append(f.id, f.file);
                    formData.append(f.id+'_data', [this.campaign,this.adgroup,f.width,f.height,f.click_url,f.name,f.id]);
                });

                this.$http.post('https://api.purplepatch.online/advertiser/banner/upload/', formData, { 
                    headers: {
                        Authorization:'Bearer ' + accessToken,
                        'x-access-token': accessToken,
                        'Content-Type': 'multipart/form-data'
                    } 
                })
                .then(response => {
                    console.log(response);
                    return;
                    this.show = false;
                    if( response.status == '201' ){
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: response.data.msg,
                                icon: 'AwardIcon',
                                variant: 'success',
                                text: response.data.msg
                            },
                        });
                        // return router.push({ name: 'advertiser-campaigns' });
                    } else {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: response.data.msg,
                                icon: 'BellOffIcon',
                                variant: 'error',
                                text: response.data.msg
                            },
                        });
                        // return router.push({ name: 'advertiser-banners' });
                    }
                })
                .catch(error => {
                    this.show = false;
                    if( response.status != '201' ){
                        this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: error.msg,
                            icon: 'CoffeeIcon',
                            variant: 'error',
                            text: error.msg
                        },
                        });
                        return router.push({ name: 'advertiser-banners' });
                    }
                });
            }

            if( this.adgroup && this.bannertype == 2 ){
                var html_data = {
                    adgroup_id: this.adgroup,
                    banner_name: this.banner_name,
                    html: this.htmlCode
                };

                this.$http.post('https://api.purplepatch.online/advertiser/banner/html/', html_data, { 
                    headers: {
                        Authorization:'Bearer ' + accessToken,
                        'x-access-token': accessToken
                    } 
                })
                .then(response => {
                    console.log(response);
                    this.show = false;
                    if( response.status == '201' ){
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: response.data.msg,
                                icon: 'AwardIcon',
                                variant: 'success',
                                text: response.data.msg
                            },
                        });
                        return router.push({ name: 'advertiser-campaigns' });
                    } else {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: response.data.msg,
                                icon: 'BellOffIcon',
                                variant: 'error',
                                text: response.data.msg
                            },
                        });
                        return router.push({ name: 'advertiser-banners' });
                    }
                })
                .catch(error => {
                    this.show = false;
                    if( response.status != '201' ){
                        this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: error.msg,
                            icon: 'CoffeeIcon',
                            variant: 'error',
                            text: error.msg
                        },
                        });
                        return router.push({ name: 'advertiser-banners' });
                    }
                });
            }
        },
        getAdGroupList(data){
            this.adgroup='';
            const accessToken = useJwt.getToken('accessToken')
            this.$http.post('https://api.purplepatch.online/advertiser/adgroup-list', {'campaign_id':data}, {
                headers: {
                    Authorization:'Bearer ' + accessToken,
                    'x-access-token': accessToken 
                }
            })
        .then(res => {
                if(res.data){
                    this.adgroupdisabled=false;
                    this.adgroups=res.data;
                }
            })
        },
        handleBanners(banners){
            this.banners = banners;
        },
        makeFormData( file ) {
            const form = new FormData();
            form.append("file", file, file.name);
            return form;
        },
    },
    created() {
        const accessToken = useJwt.getToken('accessToken')
        this.$http.get('https://api.purplepatch.online/advertiser/ad-groups/campaign-list', {
            headers: {
                Authorization:'Bearer ' + accessToken,
                'x-access-token': accessToken 
            }
        })
        .then(response => {
            this.campaigns = response.data;
        });
    },    
  }  
  </script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
  
  