<template>
  <b-row>
    <form
      enctype="multipart/form-data"
      novalidate
      class="file-upload-form bg-light rounded-lg py-2 px-5 d-flex items-center justify-content-center align-items-center mb-2 position-relative"
      :class="{ 'border-secondary': dragging }"
    >
      <input
        type="file"
        multiple
        class="position-absolute w-100 h-100"
        @change="handleFilesChosen"
        @dragover.prevent="handleDragOver"
        @dragleave.prevent="handleDragLeave"
        style="opacity:0;top:0;left:0;cursor: pointer;z-index:9"
      />
      <template v-if="dragging">
        <b-col md="12" class="drop-file mb-2">
          <div class="text-primary my-4 mx-auto">
            <h2 class="text-primary"> Nearly there. Let go upload
            <span class="font-medium"> {{ draggingCount }}</span> items! </h2>
          </div>
        </b-col>
      </template>
      <template v-else>
        <b-col md="12" class="drop-file">
          <b-row>
            <b-col md="12">
              <h2>Drag <em>&</em> Drop Files Here</h2>
            </b-col>
            <b-col md="12">
              <b-row class="mb-3 mt-2 mx-auto" style="max-width:fit-content;">
                <b-col md="2" class="thumbnail-block">
                  <span class="dynamic-ad-thumbnail" ad-size="300x250">
                    <span class="thumbnail-container thumb_300x250">
                      <span class="ad-thumbnail upload-ads">
                        <p></p>
                      </span>
                      <span class="ad-size">300x250</span>
                    </span>
                  </span>
                </b-col>
                <b-col md="2" class="thumbnail-block">
                  <span class="dynamic-ad-thumbnail" ad-size="728x90">
                      <span class="thumbnail-container thumb_728x90 ">
                          <span class="ad-thumbnail upload-ads">
                              <p></p>
                          </span>
                          <span class="ad-size">728x90</span>
                      </span>
                  </span>
                </b-col>
                <b-col md="2" class="thumbnail-block">
                  <span class="dynamic-ad-thumbnail" ad-size="160x600">
                      <span class="thumbnail-container thumb_160x600 ">
                          <span class="ad-thumbnail upload-ads">
                              <p></p>
                          </span>
                          <span class="ad-size">160x600</span>
                      </span>
                  </span>
                </b-col>
                <b-col md="2" class="thumbnail-block">
                  <span class="dynamic-ad-thumbnail" ad-size="468x60">
                      <span class="thumbnail-container thumb_468x60 ">
                          <span class="ad-thumbnail upload-ads">
                              <p></p>
                          </span>
                          <span class="ad-size">468x60</span>
                      </span>
                  </span>
                </b-col>
                <b-col md="2" class="thumbnail-block">
                  <span class="dynamic-ad-thumbnail" ad-size="970x90">
                      <span class="thumbnail-container thumb_970x90 ">
                          <span class="ad-thumbnail upload-ads">
                              <p></p>
                          </span>
                          <span class="ad-size">970x90</span>
                      </span>
                  </span>
                </b-col>
                <b-col md="2" class="thumbnail-block">
                  <span class="dynamic-ad-thumbnail" ad-size="300x600">
                      <span class="thumbnail-container thumb_300x600 ">
                          <span class="ad-thumbnail upload-ads">
                              <p></p>
                          </span>
                          <span class="ad-size">300x600</span>
                      </span>
                  </span>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="12">
              <div class="filetype-help-message">
                <p>You can drag multiple images here.</p>
                <p>We accept images and compressed (zip) items.</p>
              </div>
            </b-col>
          </b-row>
      </b-col>
      </template>
    </form>
  </b-row>
</template>
<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormText,
} from 'bootstrap-vue'

export default {
  components:{
    BRow,
    BCol,
    BFormGroup,
    BFormText
  },
  data() {
    return {
      dragging: false,
      draggingCount: 0,
    };
  },
  methods: {
    handleFilesChosen(e) {
      this.$emit("chosen", e.target.files);
      this.dragging = false;
    },
    handleDragOver(e) {
      this.dragging = true;
      this.draggingCount = e.dataTransfer.items.length;
    },
    handleDragLeave() {
      this.dragging = false;
      this.draggingCount = 0;
    },
  },
};
</script>

<style>
.file-upload-form{
  background: url(/fancy.png);
    text-align: center;
    border: 2px dashed #7468f0;
    padding: 30px 0;
    margin: 50px auto 20px;
    box-shadow: inset 0 2px 5px #7468f0, 0 1px 0 #fff;
}
.drop-file .thumbnail-block{float: left;}
.drop-file h2 {
    font-size: 24px;
    color: #627387;
    font-weight: 500;
    text-shadow: 0 1px 2px rgba(0,0,0,.1);
    margin: 0 auto;
}

.drop-file h2 em {
    font-family: Baskerville,Constantia,'Palatino Linotype';
    font-style: italic;
    font-weight: 400;
}

.drop-file .icon-drop-ads {
    height: 75px;
    display: inline-block;
    margin: 20px auto 0;
}

.dynamic-ad-thumbnail {
    display: table;
    width: 100%;
}

.dynamic-ad-thumbnail {
    width: 16.5%;
    /* float: left; */
}

.dynamic-ad-thumbnail .thumbnail-container {
    display: table-cell;
    vertical-align: bottom;
    text-align: center;
    height: 75px;
}

.ad-thumbnail {
    padding: 1px;
    height: 58px;
    width: 60px;
    margin: auto;
    text-align: center;
}

.dynamic-ad-thumbnail .thumbnail-container .ad-thumbnail {
    display: table;
    border: 2px solid #7468f0;
    background-color: #F5FBFB;
    margin-bottom: 5px;
    box-shadow: 1px 1px 2px #999;
}

.dynamic-ad-thumbnail .thumbnail-container.thumb_300x250 .ad-thumbnail {
    height: 31.67px;
    width: 38px;
}

.drop-file p {
    font-size: 12px;
    color: #627387;
    line-height: 1rem;
    margin-bottom: 0.5rem;
}

.dynamic-ad-thumbnail .thumbnail-container .ad-thumbnail p {
    display: table-cell;
    vertical-align: middle;
    font-size: 11px;
    color: #0B9CC7;
    line-height: 1.2;
}

.drop-file .icon-drop-ads .dynamic-ad-thumbnail .thumbnail-container .ad-thumbnail p {
    color: #0B9CC7;
}

.dynamic-ad-thumbnail .thumbnail-container .ad-restriction, .dynamic-ad-thumbnail .thumbnail-container .ad-size {
    font-size: 11px;
    color: #999;
}

.dynamic-ad-thumbnail .thumbnail-container.thumb_160x600 .ad-thumbnail{
    height:46px;
    width:12.27px
}
.dynamic-ad-thumbnail .thumbnail-container.thumb_728x90 .ad-thumbnail{
    height:10px;
    width:49px
}
.dynamic-ad-thumbnail .thumbnail-container.thumb_300x250 .ad-thumbnail{
    height:31.67px;
    width:38px
}
.dynamic-ad-thumbnail .thumbnail-container.thumb_320x50 .ad-thumbnail{
    height:4.69px;
    width:30px
}
.dynamic-ad-thumbnail .thumbnail-container.thumb_300x600 .ad-thumbnail{
    height:46px;
    width:23px
}

.dynamic-ad-thumbnail .thumbnail-container.thumb_970x90 .ad-thumbnail{
    height:12.63px;
    width:49px
}
.dynamic-ad-thumbnail .thumbnail-container.thumb_468x60 .ad-thumbnail{
    height:4.69px;
    width:31.5px
}
</style>