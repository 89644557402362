<template>
  <b-row>
    <b-col md="12">
      <b-row class="p-2 mb-1 border-primary">
        <b-col md="8">

          <b-row class="">
            <b-col md="12">
              <b-form-group
                  label="Banner Name"
                  label-for="banner_name"
              >
                <b-form-input 
                    v-model="file_name"
                    placeholder="file name"
                    @change="bannerName"
                />
              </b-form-group>
            </b-col>
            <b-col md="12" class="mt-2">
              <b-form-group
                  label="Click URL"
                  label-for="click_url"
              >
                <b-form-input 
                    v-model="click_url"
                    placeholder="https://example.com"
                    @change="clicUrlkUpload"
                />
              </b-form-group>
            </b-col>
          </b-row>

        </b-col>
        <b-col md="4" class="text-center">
          <img style="max-width:100%" :src="banner_img" alt="">
          <b-row class="mt-1">
            <b-col md="6">
              <span v-if="this.upload.file" class="font-weight-bolder" >{{this.upload.width}}x{{this.upload.height}}</span>
            </b-col>
            <b-col md="6">
              <span v-if="this.upload.file" class="text-danger font-weight-bolder" >{{ sizeDisplay }} KB </span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
</b-col>
</b-row>
</template>
<script>
import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BInputGroup,
} from 'bootstrap-vue' 

export default {
  components:{
    BFormInput,
    BForm,
    BInputGroup,
    BFormGroup,
    BCol,
    BRow
  },
  props: {
    upload: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      file_name:'',
      click_url:'',
      banner_img:'',
      banner_width:0,
      banner_height:0
    };
  },
  computed: {
    sizeDisplay() {
      const img = new Image()
      img.src = URL.createObjectURL(this.upload.file)
      this.banner_img = img.src;
      img.onload = () => {
        this.upload.width = img.width;
        this.upload.height = img.height;
      }
      var filesize = (this.upload.file.size / 1000).toFixed(2);
      return filesize;
    },
  },
  methods: {
    makeFormData(file) {
      const form = new FormData();
      form.append("file", file, file.name);
      return form;
    },
    startUpload() {
      console.log(this.upload.file);
      return;
    },
    acceptedSize(size){
      return ['468x60', '728x90', '300x250', '336x280', '160x600', '120x600', '300x100', '300x600', '320x50', '970x250'].includes(size); 
    },
    clicUrlkUpload(){
      this.upload.click_url=this.click_url;
    },
    bannerName(){
      this.upload.name=this.file_name;
    }
  },
  created(){
    this.file_name=this.upload.file.name;
  }
};
</script>
